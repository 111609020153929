<template>
  <div>
    <v-card flat>
      <v-card-title><h3>Face Scan</h3></v-card-title>
      <v-card-subtitle class="mt-n4 mb-2">
        <strong
          >(To grant enrolled members access to face scanning, upload their
          profile picture)
        </strong>
        <div class="mt-2">
          click on the Capture Photo button once they are positioned correctly.
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-row align="center">
          <v-col
            cols="12"
            v-if="cameras.length == 0"
            md="6"
            lg="6"
            xs="12"
            xl="6"
            sm="12"
          >
            <div class="text-center font-weight-bold">No Camera Connected</div>
          </v-col>
          <v-col cols="12" v-else md="6" lg="6" xs="12" xl="6" sm="12">
            <v-select
              v-if="cameras.length > 1"
              v-model="selectedCameraIndex"
              :items="cameraOptions"
              style="max-width: 150px"
              outlined
              label="Select a Camera"
              dense
            >
            </v-select>
            <div class="page-container">
              <div class="video-container" v-if="selectedCameraIndex !== null">
                <video ref="videoElement" class="camera-video" autoplay></video>
                <div class="face-overlay"></div>
              </div>
            </div>
            <v-card-actions class="justify-center">
              <v-btn
                v-if="selectedCameraIndex !== null"
                @click="capturePhoto"
                :loading="buttonload"
                class="gradient-bg white--text"
              >
                Capture Photo
              </v-btn>
            </v-card-actions>
          </v-col>

          <v-col cols="12" md="6" lg="6" xs="12" xl="6" sm="12">
            <v-data-table
              :height="height"
              :items="dataItems"
              :headers="dataHeaders"
              :loading="isLoadingLocations"
              :fixed-header="fixed"
              :search="search"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.swipe_date`]="{ item }">
                <span style="margin-left: -10px">
                  {{ get_date(item) }}
                </span>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" v-if="item.source == 'WHATSAPP'">
                      <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'CAMERA'">
                      <v-icon color="#757575" small>mdi-camera</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                      <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'FACE'">
                      <v-icon color="#757575" small
                        >mdi-face-recognition</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DATACORRECTION'">
                      <v-icon color="#757575" small>mdi-human-edit</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'PROXY'">
                      <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BEACON'">
                      <v-icon color="#757575" small>mdi-bluetooth-audio</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BOX'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                      <v-icon color="#757575" small>
                        mdi-desktop-classic</v-icon
                      >
                    </div>
                  </template>
                  <span v-if="item.source == 'WHATSAPP'" class="white--text"
                    >Source: Whatsapp</span
                  >
                  <span v-else-if="item.source == 'CAMERA'" class="white--text"
                    >Source : QR</span
                  >
                  <span v-else-if="item.source == 'DESKTOP'" class="white--text"
                    >Source : Desktop</span
                  >
                  <span class="white--text" v-else-if="item.source == 'RESWIPE'"
                    >Source : Re-Scan</span
                  >
                  <span
                    v-else-if="item.source == 'DATACORRECTION'"
                    class="white--text"
                  >
                    Source : Data Correction</span
                  >
                  <span v-else-if="item.source == 'PROXY'" class="white--text">
                    Source : PROXY</span
                  >
                  <span v-else-if="item.source == 'BOX'" class="white--text">
                    Source : BOXs</span
                  >
                  <span v-else-if="item.source == 'BEACON'" class="white--text">
                    Source : BEACON</span
                  >
                  <span v-else-if="item.source == 'FACE'" class="white--text">
                    Source : FACE</span
                  >
                </v-tooltip>
              </template>
              <template v-slot:[`no-data`]>
                <v-alert class="gradient-bg2 white--text"
                  >No swipe data available!</v-alert
                >
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" class="gradient-bg2 white--text">
                  No records found!
                </v-alert>
              </template>
            </v-data-table>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn
                class="mt-n4 mr-2 text-capitalize"
                color="primary"
                text
                @click="$router.push('/ScanLogs')"
                ><u>View More</u> >></v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { face_swipes } from "@/graphql/mutations.js";
import { list_swipes_for_faces } from "@/graphql/queries.js";
import Snackbar from "@/components/SnackBar.vue";
export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      cameras: [],
      latitude: null,
      longitude: null,
      error: false,
      buttonload: false,
      isLoadingLocations: false,
      errorMessage: "",
      SnackBarComponent: {},
      height: 0,
      selectedCameraIndex: null,
      capturedPhoto: null,
      videoStream: null,
      search: "",
      timeZone: "",
      fixed: true,
      dataItems: [],
      count: 0,
      dataHeaders: [
        { text: "", value: "type" },
        { text: "Swipe Date", value: "swipe_date" },
        { text: "Member Name", value: "user_name" },
        { text: "Location Name", value: "box_location" },
      ],
    };
  },
  async created() {
    // var data =this.$store.getters.get_org_user_details[0];
    // console.log(data.user_country_code +data.user_contact_number);
    // console.log( this.$store.getters.get_org_user_details[0]);
    this.height = window.innerHeight - 240;
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await this.get_members_by_status();
    await this.getLocation();
    await this.loadCameras();
    await this.getLocation();
    await this.getLocation();
    if (this.cameras.length > 0) {
      this.selectedCameraIndex = 0; // Select the first camera by default
    }
    // console.log(this.selectedCameraIndex);
    // console.log(this.cameras.length, "length");
  },
  beforeDestroy() {
    this.stopCamera();
  },
  computed: {
    cameraOptions() {
      if (this.cameras.length === 2) {
        return [
          { text: "Device 1", value: 0 },
          { text: "Device 2", value: 1 },
        ];
      } else {
        return this.cameras.map((camera, index) => {
          return { text: camera.label, value: index };
        });
      }
    },
  },
  methods: {
    get_date(date) {
      const options = {
        timeZone:
          date.time_zone == null
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : date.time_zone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date.swipe_time_stamp * 1000).toLocaleString(
        "en-US",
        options
      );

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    getCameraLabel(camera, index) {
      if (this.cameras.length === 2) {
        return `Device ${index + 1}`;
      } else {
        return camera.label;
      }
    },
    async loadCameras() {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          this.cameras = devices.filter(
            (device) => device.kind === "videoinput"
          );
        } catch (error) {
          console.error("Error enumerating devices:", error);
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Devices is not supported.",
        };
        // Handle the case where enumerateDevices is not available
        console.error("enumerateDevices is not supported.");
        // You can provide a message to the user or take appropriate action here.
      }
    },
    async startCamera() {
      try {
        const constraints = {
          video: { deviceId: this.cameras[this.selectedCameraIndex].deviceId },
        };
        this.videoStream = await navigator.mediaDevices.getUserMedia(
          constraints
        );
        this.$refs.videoElement.srcObject = this.videoStream;
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },
    async capturePhoto() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.videoElement.videoWidth;
      canvas.height = this.$refs.videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.$refs.videoElement, 0, 0, canvas.width, canvas.height);
      this.capturedPhoto = canvas.toDataURL("image/png");
      await this.getLocation();
      if (this.count == 1) {
        this.capture_photo();
      }
      // setTimeout(() => {
      //   this.capture_photo();
      // }, 1000);

      // alert("Photo captured!");
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.error = false;
            this.count = 1;
          },
          (error) => {
            this.error = true;
            this.errorMessage = `Error getting location: ${error.message}`;
          }
        );
      } else {
        this.error = true;
        this.errorMessage = "Geolocation is not supported by this browser.";
      }
    },
    async capture_photo() {
      this.buttonload = true;
      var data = this.$store.getters.get_org_user_details[0];

      try {
        let result = await API.graphql(
          graphqlOperation(face_swipes, {
            input: {
              byte_code: this.capturedPhoto.split("data:image/png;base64,")[1],

              user_full_contact_number:
                data.user_country_code + data.user_contact_number,
              user_lat: this.latitude,
              user_long: this.longitude,
            },
          })
        );
        this.buttonload = false;
        var response = result.data.face_swipes;
        if (response.QRStatus == "SUCCESS") {
          this.buttonload = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
          this.buttonload = false;
          this.get_members_by_status();
        } else {
          this.buttonload = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
          this.buttonload = false;
        }

        // if()
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.adminLoading = false;
      }
    },
    async get_members_by_status() {
      this.isLoadingLocations = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_swipes_for_faces, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        this.isLoadingLocations = false;
        var respnse = JSON.parse(result.data.list_swipes_for_faces).data;
        this.dataItems = respnse;
        // console.log(this.dataItems);
      } catch (err) {
        this.isLoadingLocations = false;
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingLocations = false;
        this.overlay = false;
      }
    },
  },
  watch: {
    selectedCameraIndex(newIndex, oldIndex) {
      if (newIndex !== oldIndex) {
        this.stopCamera();
        if (newIndex !== null) {
          this.startCamera();
        }
      }
    },
  },
};
</script>
  
  <style>
.face-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; /* Adjust this size according to your layout */
  height: 200px; /* Adjust this size according to your layout */
  border: 2px dashed red; /* Add a dashed red border as a guide */
  pointer-events: none; /* Prevent the overlay from blocking interactions with the video */
  z-index: 2; /* Place the overlay above the video */
}
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%; /* Adjust the width as needed */
  padding-top: 70%; /* 1:1 aspect ratio */
  border: 2px solid black;
  margin-top: 16px;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.camera-video {
  transform: scaleX(-1);
}
</style>
  